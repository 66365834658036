import $ from 'jquery';
import React, {ChangeEvent, Component, FormEvent, ReactElement} from 'react';
import {Helmet} from 'react-helmet-async';
import {Action} from 'redux';

import validatePhone from 'infra/validators/phone';
import MarketLayout, {reducer} from 'web/components/market_layout';
import assetPath from 'web/helpers/asset_path';
import {MarketLayoutStore} from 'web/components/market_layout/store_builder';

const iosUrl = `http://apple.co/1LbnTPm`;
const androidUrl = `https://play.google.com/store/apps/details?id=com.goodeggs.mobile.production`;

interface IosPromoPageProps {}
interface IosPromoPageState {
  phone?: string;
  errorMessage?: string;
  success: boolean;
}

export default class IosPromoPage extends Component<IosPromoPageProps, IosPromoPageState> {
  public constructor(props: IosPromoPageProps) {
    super(props);
    this.state = {
      success: false,
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  private setErrorMessage(errorMessage: string): void {
    this.setState({errorMessage});
  }

  private handleOnSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const {phone} = this.state;
    if (!validatePhone(phone)) {
      this.setErrorMessage('Invalid phone number');
      return;
    }

    $.ajax({
      url: '/app/sms',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({phone, platform: 'ios'}),
      success: () => {
        this.setState({success: true});
      },
      error: ({status}) => {
        this.setErrorMessage(
          status === 406
            ? "We couldn't send a text message to that number, please try again."
            : 'Oops, something went wrong. Please try again.',
        );
      },
    });
  };

  private handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({phone: event.target?.value});
  };

  public static reducer(state: MarketLayoutStore, action: Action): MarketLayoutStore {
    return reducer(state, action);
  }

  public render(): ReactElement {
    return (
      <MarketLayout>
        <Helmet>
          <title>Our App | Good Eggs</title>
          <body className="ios-promo-page__body" />
        </Helmet>

        <div className="content-container ios-promo-page">
          <div className="ios-promo-grid">
            <div className="ios-promo-grid__left">
              <h3>
                The easiest way to
                <br />
                shop on Good Eggs.
              </h3>
              {this.state.success ? (
                <div className="ios-promo-grid__sms-success">
                  <p>Great! We{"'"}ve sent you a link.</p>
                  <a href="/app">Send to another phone number</a>
                </div>
              ) : (
                <div className="ios-promo-grid__sms">
                  <form className="js-form-sms" onSubmit={this.handleOnSubmit}>
                    <div className="mobile-number-form-sms">
                      <input
                        className="form-control ios-promo-grid__sms__input js-input-mobile-number"
                        type="text"
                        placeholder="Mobile Number"
                        onChange={this.handleOnChange}
                      />
                      <button
                        className="button ios-promo-grid__sms__send-button js-send-sms-button"
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                    <div className="ios-promo-grid__sms__input-error js-sms-error-message">
                      {this.state.errorMessage}
                    </div>
                  </form>
                  <div className="ios-promo-grid__sms__subtext">
                    Text yourself the download link.
                  </div>
                  <div className="ios-promo-grid__sms__sub-subtext">
                    Standard text messaging and data rates may apply.
                  </div>
                </div>
              )}
              <div className="ios-promo-grid__left__app-store mobile">
                <a href={iosUrl}>
                  <img
                    className="ios-promo-grid__left__app-store__image"
                    src={`${assetPath('/img/nettle/app_promo/app_store.png')}?auto=format`}
                  />
                </a>
              </div>

              <div className="ios-promo-grid__left__app-store desktop">
                <a href={iosUrl}>
                  <img
                    className="ios-promo-grid__left__app-store__image"
                    src={`${assetPath('/img/nettle/app_promo/app_store.png')}?auto=format`}
                  />
                </a>
              </div>

              <div className="ios-promo-grid__left__app-store mobile">
                <a href={androidUrl}>
                  <img
                    className="ios-promo-grid__left__app-store__image"
                    src={`${assetPath('/img/nettle/app_promo/google_play.png')}?auto=format`}
                  />
                </a>
              </div>

              <div className="ios-promo-grid__left__app-store desktop">
                <a href={androidUrl}>
                  <img
                    className="ios-promo-grid__left__app-store__image"
                    src={`${assetPath('/img/nettle/app_promo/google_play.png')}?auto=format`}
                  />
                </a>
              </div>
            </div>
            <div className="ios-promo-grid__right">
              <img
                className="ios-promo-grid__right__iphone6-mock"
                src={`${assetPath('/img/nettle/app_promo/iphone-12--white@2x.png')}?auto=format`}
              />
            </div>
          </div>
          <div className="download-the-app-container mobile">
            <a className="download-the-app" href={iosUrl}>
              <div className="button ios-promo-grid__left__download-button">Download The App</div>
            </a>
          </div>
        </div>
      </MarketLayout>
    );
  }
}
